<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('myPublishedProjects.multiperson')/*发起多人访谈*/"/>
        <div class="main">
            <div class="text-group2">
                <h1>{{ threeMinuteProjectDetails.project_name }}</h1>
                <div class="text-group2-content">
                    <!--<span class="publish-date">{{ // details_data.project_intro }}</span>-->
                    <div class="publish-date fl">{{$t('publish.Cover addressreleasedOneDayAgo')}}<!--1天前发布--></div>
                    <div class="brief-intro over fl" style="width: 70%"
                    >{{ threeMinuteProjectDetails.project_intro }}
                    </div
                    >
                </div>
            </div>
            <div class="people-wrap" style="height: auto;">
                <div class="wrap1">
                    <div class="people-info">
                        <!-- <img class="head-icon fl" :src="details_data.avatar" alt="" /> -->
                        <img :src="threeMinuteProjectDetails.initiator_avatar" alt="" class="head-icon fl"/>
                        <div class="people-info-text fl">
                            <!--<h1 class="over" v-if="delivery_data.pd_num != 4">{{ details_data.name }}</h1>-->
                            <!--<h1 class="over" v-else>{{ details_data.referrer_name }}</h1>-->
                            <h1 class="over">{{ threeMinuteProjectDetails.initiator_name }}</h1>
                            <h2 class="over">{{$t('myInterviewDetail.publisher')}}<!--发布者--></h2>
                            <!--<h2 class="over">-->
                            <!--  {{ details_data.company_name }} · {{ details_data.position }}-->
                            <!--</h2>-->
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <MultiPerson :list="inviteesMapList"/>
            </div>


            <div class="appointment-time" style="margin-top: 64px">
                <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
                <div class="seven_box">
                    <!-- 翻译：预约时间-->
                    <p style="margin: 7px 0 26px 0">
                        {{$t('myProject.multiPersonFreeCommunicationContent')}}
                        <!--多人免费沟通。您可以预约1个时间段。可选择日期范围：系统时间往后7-37天之间-->
                    </p>
                    <div class="seven_item">
                        <div class="fl">
                            <!-- <el-date-picker v-model="value1" type="date" placeholder="选择日期">
                            </el-date-picker> -->
                            <el-date-picker :picker-options="pickerOptions"
                                            :placeholder="$t('publish.selectDateTime1')/*选择日期时间1*/" class="input-class"
                                            type="datetime"
                                            v-model="form.inviteTime" value-format="yyyy-MM-dd HH:mm">
                            </el-date-picker>
                        </div>
                        <img alt="" class="fr" src="@/assets/images/icon_date@2x.png" width="20px"/>
                    </div>
                </div>
                <div class="form-wrap">
                    <div class="input-text-area">
                        <div class="ita-label">
                            <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                        </div>
                        <div style="display: inline-block;">
                            <el-select filterable style="width:200px !important;height:50px;" v-model="form.mobileCode">
                                <el-option :key="id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                           :value="item.code" v-for="(item, id) in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                            <!--<span v-if="form.mobileCode" style="position:absolute;margin-left:-40px;">+{{form.mobileCode}}</span>-->
                            <div class="v-line"></div>
                            <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                                   v-model="form.mobileNumber"/>
                        </div>
                    </div>
                    <div class="input-text-area">
                        <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                        <div class="ita-input-front">
                            <input :placeholder="$t('publish.input')" class="iif-input" type="text"
                                   v-model="form.mailBox"/>
                        </div>
                    </div>
                    <div class="input-text-area">
                        <div class="ita-label"><span>* </span>WhatsApp</div>
                        <div style="display: inline-block;">
                            <el-select filterable style="width:200px !important;height:50px;"
                                       v-model="form.whatsappCode">
                                <el-option :key="index" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                           :value="item.code" v-for="(item,index) in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                            <!--<span v-if="form.whatsappCode" style="position:absolute;margin-left:-40px;">+{{form.whatsappCode}}</span>-->
                            <div class="v-line"></div>
                            <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                                   v-model="form.whatsApp"/>
                        </div>

                    </div>
                </div>
                <div class="leave-message">
                    <h1>{{$t('publish.kefuliuyan')}}<!--关于预约给客服的留言--></h1>
                    <textarea :placeholder="$t('publish.input')" cols="30" id="q" name="" rows="10"
                              v-model="form.inviteMessage">
          </textarea>
                </div>
                <div>
                    <CustomButton @click.native="initiatePeopleInterviewForThree()" style="margin-left: 17px"
                                  type="black">{{$t('publish.releaseInterview')}}<!--发布访谈--></CustomButton>
                </div>
            </div>


            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t("vipRecommend.projectDetail") }}</h1>
                <div class="rich-text">
                    <p v-html="threeMinuteProjectDetails.details"></p>
                    <!-- <img src="@/assets/images/rich_content.png" style="width: 695px" alt="" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/my_interview/receive_interview_vip/AppointmentTime";
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";
    import {multiplayerInterviewForDetails, initiatePeopleInterviewForThree} from "@/api/myProjectApi/interviewApi"
    import {dateLimit} from "@/mixins/date_limit";


    export default {
        name: "index",
        mixins: [dateLimit],
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            MultiPerson,
        },
        data() {
            return {
                inviteesMapList: [],//受邀人信息列表
                threeMinuteProjectDetails: '',//项目详情
                details_data: {},
                form: {
                    inviteTime: "",
                    mailBox: "",
                    whatsApp: "",
                    inviteMessage: "",
                    mobileCode: '86',
                    whatsappCode: '86',
                },
                areaList: [],
            };
        },
        mounted() {
            //获取地区编号
            this.getArea();
            this.delivery_data = this.$route.query;
            console.log(this.delivery_data)
            this.getMultiplayerInterviewForDetails();
        },
        methods: {
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //、发起人3分钟访谈，VIP私密推荐-发起多人邀约跳转页面~
            getMultiplayerInterviewForDetails() {
                let formData = {
                    inviteeIds: this.delivery_data.ids.toString(),
                    projectId: this.delivery_data.projectId,
                    token: localStorage.getItem("token"),
                };
                multiplayerInterviewForDetails(formData).then((res) => {
                    if (res.code == 200) {
                        this.inviteesMapList = res.data.inviteesMapList;
                        this.threeMinuteProjectDetails = res.data.threeMinuteProjectDetals;
                    } else {
                        this.$message.error(res.msg)
                    }
                });

            },
            //、3分钟访谈发起多人邀约~
            initiatePeopleInterviewForThree() {
                let formData = {
                    inviteeIds: this.delivery_data.ids.toString(),
                    projectId: this.delivery_data.projectId,
                    invitationReason: this.form.inviteMessage,
                    invitationTime: this.form.inviteTime,
                    inviteType: 2,
                    inviteeSource: 1,
                    mailBox: this.form.mailBox,
                    mobileNumber: this.delivery_data.projectId,
                    whatsApp: this.form.whatsApp,
                    mobileCode: this.form.mobileCode,
                    whatsappCode: this.form.whatsappCode,
                    token: localStorage.getItem("token"),
                };
                initiatePeopleInterviewForThree(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                });

            },
        },
    };
</script>

<style scoped>
    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .seven_item {
        width: 587px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .seven_item img {
        margin-top: 15px;
    }

    .appointment-time {
        margin-top: 16px;
    }

    .appointment-time > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .appointment-time > .tip {
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .appointment-time > h2 {
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .appointment-time > h3 {
        margin-top: 5px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .time-list {
        margin-top: 23px;
    }

    .time-list > li {
        margin-bottom: 26px;
    }

    .time-list > li:last-child {
        margin-bottom: 0;
    }

    .leave-message {
        margin-top: 55px;

    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }


    .radio-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .form-wrap {
        margin-top: 57px;

    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #D6D6D6;
        margin-right: 45px;
    }

    .ita-input-front > span {
        display: inline-block;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .people-wrap {
        height: 49px;
        margin-top: 31px;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;

    }

    /**/
    .wrap1 {
        box-sizing: border-box;
        height: 142px;
        padding-top: 38px;

        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
    }

    .wrap2 {
        padding-top: 38px;
        margin-bottom: 39px;
    }

    .wrap2 > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 34px;
    }

    .people-info {
        overflow: hidden;
    }

    .container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        margin: 0 auto;
    }

    .text-group1 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .text-group1 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .text-group1 > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 8px;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        padding-top: 30px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    .reason {
        margin-top: 65px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .project-detail {
        margin-top: 72px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }
</style>
